import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

const Header = ({ siteTitle, menuLinks }) => (
  <header>
    <h1>
      <Link to="/" className="home-btn">
        {/* {siteTitle} */}
        textschön
      </Link>
    </h1>
    <nav>
      <ul>
        {menuLinks.map((link) => (
          <li key={link.name}>
            <Link to={link.link}>{link.germanTitle}</Link>
          </li>
        ))}
      </ul>
    </nav>
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
