// import * as React from 'react';
// import { Link } from 'gatsby';

// const Layout = ({ pageTitle, children }) => {
//   return (
//     <div>
//       <title>{pageTitle}</title>
//       <nav>
//         <ul>
//           <li>
//             <Link to="/">Home</Link>
//           </li>
//         </ul>
//       </nav>
//       <main>
//         <h1>{pageTitle}</h1>
//         {children}
//       </main>
//     </div>
//   );
// };

// export default Layout;

import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import Header from './header';


const Layout = ({ children, location }) =>{

  const path = location.pathname.split("/")[1]

  const cssPathClass = (location) => {
    console.log('path',path);
    const className = path 
      ? path 
      : 'home'
    return className
  }

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
              menuLinks {
                name
                link
                germanTitle
              }
            }
          }
        }
      `}
      render={(data) => (
        <>
          <Header
            menuLinks={data.site.siteMetadata.menuLinks}
            siteTitle={data.site.siteMetadata.title}
          />
          <main className={location.pathname}>
            <Helmet
              title={`Antonia Müller - Textschön - ${path.toUpperCase()}`}
              bodyAttributes={{ class: cssPathClass(location) }}
              htmlAttributes={{
                lang: 'de',
              }}
              meta={[
                { name: 'description', content: 'Antonia Müller, Lektorat in Berlin' },
                { name: 'keywords', content: 'Berlin, Lektorat, Lektorieren, Korrektur, Texte, Kommunikation' },
              ]}
            ></Helmet>
            <div>{children}</div>
          </main>
        </>
      )}
    />
  );
};

export default Layout;